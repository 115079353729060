// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main {
    position:absolute;
    top:45%;
    transform:translateY(-50%);
    width: 100%;
}

h1, p {
    text-align: center;
}

span, a {
    color: white;
    background-color: black;
}

a {
    position: relative;
    text-decoration: none;
  }
  
a:hover {
    background-color: #fff;
    color: #000;
    text-decoration: underline;
}

footer {
    position:absolute;
    bottom:15%;
    text-align: center;
    width: 100%;
}

.source>a {
    font-size: 12px;
    background-color: #fff;
    color: grey;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,OAAO;IACP,0BAA0B;IAC1B,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;EACvB;;AAEF;IACI,sBAAsB;IACtB,WAAW;IACX,0BAA0B;AAC9B;;AAEA;IACI,iBAAiB;IACjB,UAAU;IACV,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,sBAAsB;IACtB,WAAW;AACf","sourcesContent":["main {\n    position:absolute;\n    top:45%;\n    transform:translateY(-50%);\n    width: 100%;\n}\n\nh1, p {\n    text-align: center;\n}\n\nspan, a {\n    color: white;\n    background-color: black;\n}\n\na {\n    position: relative;\n    text-decoration: none;\n  }\n  \na:hover {\n    background-color: #fff;\n    color: #000;\n    text-decoration: underline;\n}\n\nfooter {\n    position:absolute;\n    bottom:15%;\n    text-align: center;\n    width: 100%;\n}\n\n.source>a {\n    font-size: 12px;\n    background-color: #fff;\n    color: grey;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Slider_container__5PfnX {
    width:50%;
    margin: 0 auto ;
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: center;
}

h2 {
    width: 60px;
    min-width: 60px;
}

input[type="range"] {
    width: 100%;
    /* Set the track color to black */
    background: black;
    /* Remove the default track border */
    border: none;
    /* Set the track height */
    height: 14px;
    /* Adjust other properties as needed */
  }
  
  /* Styling for the slider thumb */
  input[type="range"]::-webkit-slider-thumb {
    /* Set the thumb color to black for WebKit browsers (e.g., Chrome) */
    background: black;
    /* Set the thumb size */
    width: 16px;
    height: 16px;
    /* Add a border or other styles as desired */
  }
  
  input[type="range"]::-moz-range-thumb {
    /* Set the thumb color to black for Firefox */
    background: black;
    /* Set the thumb size */
    width: 16px;
    height: 16px;
    /* Add a border or other styles as desired */
  }

label {
    width: 60px;
}

@media (max-width: 768px) {
    /* Styles for screens up to 768 pixels */
    /* Mobile devices or smaller screens */
  
    .Slider_container__5PfnX {
      width: 90%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/Components/Slider/Slider.module.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,eAAe;IACf,aAAa;IACb,SAAS;IACT,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,iCAAiC;IACjC,iBAAiB;IACjB,oCAAoC;IACpC,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,sCAAsC;EACxC;;EAEA,iCAAiC;EACjC;IACE,oEAAoE;IACpE,iBAAiB;IACjB,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,4CAA4C;EAC9C;;EAEA;IACE,6CAA6C;IAC7C,iBAAiB;IACjB,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,4CAA4C;EAC9C;;AAEF;IACI,WAAW;AACf;;AAEA;IACI,wCAAwC;IACxC,sCAAsC;;IAEtC;MACE,UAAU;IACZ;EACF","sourcesContent":[".container {\n    width:50%;\n    margin: 0 auto ;\n    display: flex;\n    gap: 16px;\n    justify-content: space-between;\n    align-items: center;\n}\n\nh2 {\n    width: 60px;\n    min-width: 60px;\n}\n\ninput[type=\"range\"] {\n    width: 100%;\n    /* Set the track color to black */\n    background: black;\n    /* Remove the default track border */\n    border: none;\n    /* Set the track height */\n    height: 14px;\n    /* Adjust other properties as needed */\n  }\n  \n  /* Styling for the slider thumb */\n  input[type=\"range\"]::-webkit-slider-thumb {\n    /* Set the thumb color to black for WebKit browsers (e.g., Chrome) */\n    background: black;\n    /* Set the thumb size */\n    width: 16px;\n    height: 16px;\n    /* Add a border or other styles as desired */\n  }\n  \n  input[type=\"range\"]::-moz-range-thumb {\n    /* Set the thumb color to black for Firefox */\n    background: black;\n    /* Set the thumb size */\n    width: 16px;\n    height: 16px;\n    /* Add a border or other styles as desired */\n  }\n\nlabel {\n    width: 60px;\n}\n\n@media (max-width: 768px) {\n    /* Styles for screens up to 768 pixels */\n    /* Mobile devices or smaller screens */\n  \n    .container {\n      width: 90%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Slider_container__5PfnX`
};
export default ___CSS_LOADER_EXPORT___;
